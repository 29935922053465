import Hero1 from "../../../assets/images/hero/hero-1.jpg";
import Hero2 from "../../../assets/images/hero/hero-2.jpg";
import Hero5 from "../../../assets/images/hero/hero-5.jpg";
import Hero6 from "../../../assets/images/hero/hero-6.jpg";
import Hero7 from "../../../assets/images/hero/hero-7.jpg";
import Hero8 from "../../../assets/images/hero/hero-8.jpg";
import Hero9 from "../../../assets/images/hero/hero-9.jpg";
import Hero10 from "../../../assets/images/hero/hero-10.jpg";
import Hero11 from "../../../assets/images/hero/hero-11.jpg";
import Hero13 from "../../../assets/images/hero/hero-13.jpg";
import Hero14 from "../../../assets/images/hero/hero-14.jpg";
import Hero15 from "../../../assets/images/hero/hero-15.jpg";

export const Heading = "Future Design & Infra Pvt. Ltd.";
export const SubHeading = "We Make Tomorrow";
export const WhatWeDoList = [
  Hero1,
  Hero2,
  Hero8,
  Hero9,
  Hero10,
  Hero5,
  Hero14,
  Hero11,
  Hero7,
  Hero6,
  Hero15,
  Hero13,
];
export const SliderSettings = {
  dots: true,
  infinite: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  swipe: true,
};
