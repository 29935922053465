import { Heading, SubHeading, WhatWeDoList, SliderSettings } from "./constants";
import "./styles.scss";
import Slider from "react-slick";

const Hero = () => {
  return (
    <div className="text-center" id="top">
      <div className="hero-heading">{Heading}</div>
      <div className="hero-subheading">{SubHeading}</div>
      <div className="hero-images">
        {/* <img src={HeroMobile} alt="hero-img" className="hero-mobile" /> */}
        {/* <img src={HeroSideFruit} alt="hero" className="hero-fruits" /> */}
        <Slider {...SliderSettings}>
          {WhatWeDoList.map((item, index) => {
            return (
              <div className="hSlider text-center" key={index}>
                <img src={item} alt="what-we-do" />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Hero;
