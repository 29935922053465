import "./styles.scss";
import { Copyright, ContactInfo, FollowTxt } from "./constants";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer" data-scroll-index="5">
      <div className="box">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mt-5">
              {ContactInfo.map((item, index) => (
                <div className="contact-info" key={index}>
                  <span>
                    <img src={item.src} alt={item.name} />
                  </span>
                  <Link to={item.to} target="_blank" rel="noreferrer">
                    {item.txt}
                  </Link>
                </div>
              ))}
              <div className="follow">
                {FollowTxt}
                  <div className="icons">
                    <Link to="https://www.facebook.com/futuredesignandinfrapvtltd?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-facebook"></i>
                    </Link>
                    <Link to="https://instagram.com/future_design_infra_pvt_ltd?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-instagram"></i>
                    </Link>
                    <Link to="https://www.linkedin.com/in/future-design-and-infra-pvt-ltd-84a0bb279" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-linkedin"></i>
                    </Link>
                    <Link to="https://twitter.com/fdi_pvt" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link to="https://youtube.com/@FDIPVTLTD" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-youtube"></i>
                    </Link>
                  </div>
              </div>
            </div>
          </div>
          <div className="copyright text-white text-center">{Copyright}</div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
