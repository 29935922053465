import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import NotFound from "./components/NotFound";

function App() {
  return (
    <Router>
      <Helmet>
        <title>Future Design Infra: Empowering Engineers and Interior Designers in Delhi NCR</title>
        <meta
          name="description"
          content="Discover the future of design with Future Design Infra, a leading platform for engineers and interior designers in Delhi NCR. We offer innovative solutions, cutting-edge technologies, and creative insights to transform spaces into breathtaking environments. Join us on this journey of architectural excellence and unlock limitless possibilities for your projects. Explore our services, connect with industry professionals, and redefine the future of design with Future Design Infra"
        />
      </Helmet>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
