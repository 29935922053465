import { Heading, FeaturesData, SubHeading } from "./constants";
import "./styles.scss";

const OurValues = () => {
  return (
    <div id="our-values" >
      <div className="container" data-scroll-index="2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="values-heading">{Heading}</div>
        </div>
        <div className="values-subheading">{SubHeading}</div>
        <div className="row our-values-row justify-content-between">
          {FeaturesData.map((item, index) => (
            <div
              className="col-md-6 col-lg-2 col-sm-12 our-values-col"
              key={index}
            >
              <div className="our-values-icon">
                <img src={item.img} alt={item.title} />
              </div>
              <div className="our-values-text">
                <div className="our-values-title">{item.title}</div>
                <div className="our-values-content">{item.content}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default OurValues;
