import { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import Benefits from "./Benefits";
import OurValues from "./Our-Values";
import Hero from "./Hero";
import InfoBox from "../Footer/Info";
// import Testimonials from "./Testimonials";
import AboutUs from "./About-Us";
import WhatWeBelieve from "./Believe-Why-us";
import OurServices from "./Our-Services";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ContactInfo } from "../Footer/constants";

const defaultValues = {
  name: "",
  phoneNumber: "",
  email: "",
  message: "",
  subject: "",
};
const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
  subject: yup.string().required("Subject is required"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .matches(
      /^$|^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Invalid Phone Number"
    ),
  message: yup.string().required("Message is required"),
});
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [modal, setModal] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    handleSubmit((data) => {
      var body, subject;

      body = "Name: " + data.name;
      body += "\nEmail: " + data.email;
      body += "\nPhone Number: " + data.phoneNumber;
      body += "\nMessage: " + data.message;

      subject = data.subject;
      window.location =
        `mailto:${ContactInfo[1].txt}?body=` +
        encodeURIComponent(body) +
        "&subject=" +
        encodeURIComponent(subject);
    })();
  };
  const toggle = () => setModal(!modal);

  useEffect(() => {
    reset(defaultValues);
  }, [modal, reset]);

  return (
    <div className="overflow-hidden main-container">
      <Button onClick={() => setModal(true)} className="chat-icon glow">
        <i className="fab fa fa-comments-o" /> Quick Enquiry
      </Button>
      <div className="chat-box">
        <div className={`chat-popup ${modal ? "d-block" : "d-none"}`}>
          <div className="form-container">
            <h5 className="text-center">Drop us a line!</h5>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <div className="mb-2">
                  <Input
                    type="text"
                    {...field}
                    placeholder="Name*"
                    invalid={Boolean(errors["name"])}
                  />
                  <small className="text-danger">
                    {errors["name"]?.message}
                  </small>
                </div>
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <div className="mb-2">
                  <Input
                    type="text"
                    placeholder="Email*"
                    {...field}
                    invalid={Boolean(errors["email"])}
                  />
                  <small className="text-danger">
                    {errors["email"]?.message}
                  </small>
                </div>
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <div className="mb-2">
                  <Input
                    type="number"
                    {...field}
                    placeholder="Phone Number*"
                    invalid={Boolean(errors["phoneNumber"])}
                  />
                  <small className="text-danger">
                    {errors["phoneNumber"]?.message}
                  </small>
                </div>
              )}
            />
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <div className="mb-2">
                  <Input
                    type="text"
                    placeholder="Subject*"
                    {...field}
                    invalid={Boolean(errors["control"])}
                  />
                  <small className="text-danger">
                    {errors["subject"]?.message}
                  </small>
                </div>
              )}
            />
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <div className="mb-2">
                  <Input
                    type="textarea"
                    invalid={Boolean(errors["message"])}
                    {...field}
                    placeholder="Message*"
                  />
                  <small className="text-danger">
                    {errors["message"]?.message}
                  </small>
                </div>
              )}
            />
            <div className="d-flex">
              <Button
                type="submit"
                className="btn btn-primary send"
                color="primary"
                onClick={onSubmit}
              >
                Send
              </Button>
              <Button
                type="submit"
                className="btn cancel ms-2"
                onClick={toggle}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => setModal(false)}>
        <Hero />
        <AboutUs />
        <OurValues />
        <WhatWeBelieve />
        <OurServices />
        <Benefits />
        {/* <Testimonials /> */}
        <InfoBox />
      </div>
    </div>
  );
};
export default Home;
