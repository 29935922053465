import Integrity from "../../../assets/images/our-values/integrity.webp";
import Commitment from "../../../assets/images/our-values/commitment.webp";
import Passion from "../../../assets/images/our-values/passion.webp";
import Teamwork from "../../../assets/images/our-values/team-work.webp";
import Learning from "../../../assets/images/our-values/learning.webp";


export const Heading = "Our Values";
export const SubHeading = "Unleashing Boundless Design Potential";
export const FeaturesData = [
  {
    title: "Integrity",
    content:
      "Our first principal is honesty which helps us meet the expectations we create",
    img: Integrity,
  },
  {
    title: "Commitment",
    content: "Our commitment to our work and our clients is an unbreakable bond",
    img: Commitment,
  },
  {
    title: "Passion",
    content:
      "Passion drives us to perform better, progress continually and deliver timely.",
    img: Passion,
  },
  {
    title: "Learning",
    content:
      "Our team is always learning something new to get better at what they do",
    img: Learning,
  },
  {
    title: "Team-work",
    content:
      "Our team members are all about helping each other.",
    img: Teamwork,
  },
];
