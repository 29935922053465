
import "./styles.scss";
// import BenefitsSlider from './slider';
import InteriorSolutions from "./interior-solutions";
import WhatWeDo from "./what-we-do";

const Benefits = () => {

  return (
    <div id="benefits" className="mt-5 scroll">
      <WhatWeDo/>
      <InteriorSolutions/>
      {/* <BenefitsSlider/>  */}
    </div>
  )
}

export default Benefits
