import "./styles.scss";

const AboutUs = () => {
  return (
    <div id="about-us" >
      <div className="container" data-scroll-index="1">
        <div className="d-flex align-items-center justify-content-between">
          <div className="about-us-heading">About FDI</div>
        </div>
        <div className="about-us-content">
          We are a leading construction and interior decor company that has been
          turning our client’s visions into realities. We have been devoting our
          best approach to exceed our client expectations by creating luxury
          designs and significant infrastructure in order to emerge as the best
          and top designing and Construction Company in India. A full Service
          providing company serving across pan India. We can deliver all types
          of Architectural, Construction and Renovation projects like
          Construction, Interior design / execution, Maintenance, Fire Fighting
          , MEP ( Mechanical Electrical Plumbing), Turnkey projects , general
          contractor, Collaboration and project management consultancy.
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
