import "./styles.scss";
import WhatWeBelieveImg from "../../../assets/images/what-we-believe/what-we-believe.png";

const WhatWeBelieve = () => {
    return (
    <div id="what-we-believe" >
    <div className="container">
        <div className="row">
        <div className="col-lg-6">
            <div className="d-flex align-items-center justify-content-between">
            <div className="what-we-believe-heading">What We Believe</div>
            </div>
            <div className="what-we-believe-content">
            Our core values shape our culture and define who we are. They
            underscore our commitment to our clients, to our communities and
            to each other, and provide direction for everything we do. We
            strongly believe in teamwork, ethics and excellence.
            </div>
            <div className="d-flex align-items-center justify-content-between">
            <div className="why-choose-us-heading">Why Choose Us</div>
            </div>
            <div className="why-choose-us-content">
            We aim to eliminate the task of dividing your project between
            different architecture and construction company.
            <br />
            We are a firm that offers plan, design, and build services for you
            from initial sketches to the final construction. We take
            responsibility to do a best quality plan and create best quality
            construction work as per drawing schedule. We have always strived
            to provide value to our client money, ensuring that they attain
            the highest level of satisfaction. As The General Contractor, We
            first create the highest level of trust and integrity with our
            clients.
            </div>
        </div>
        <div className="col-6 d-lg-block d-none">
            <img src={WhatWeBelieveImg} alt="what we believe"/>
        </div>
        </div>
    </div>
    </div>
);
};
export default WhatWeBelieve;
